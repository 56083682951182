import ReactInputMask from "react-input-mask-format";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { SubTitle } from "@/shared/ui";

interface IFormValues {
  pincode: string;
}

interface IConfirmPhoneForm {
  onSubmit: SubmitHandler<IFormValues>;
  error: string | null;
}

export const ConfirmPhoneForm: React.FC<IConfirmPhoneForm> = ({
  onSubmit,
  error,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormValues>();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <SubTitle>
        Мы вам позвоним! Введите последние четыре цифры номера телефона для
        получения кода.
      </SubTitle>

      {errors.pincode && (
        <label className="text-error">{errors.pincode.message}</label>
      )}

      <Controller
        name="pincode"
        control={control}
        defaultValue=""
        rules={{
          required: "Введите код подтверждения",
          validate: (value) => {
            if (value.includes("_"))
              return "Введите код подтверждения полностью";
          },
        }}
        render={({ field }) => (
          <ReactInputMask
            {...field}
            className={`input input-bordered text-center text-2xl ${
              errors.pincode ? "input-error" : ""
            }`}
            mask="9 9 9 9"
            placeholder={"_ _ _ _"}
          />
        )}
      />

      {error && <label className="text-error text-center">{error}</label>}

      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm"></span>
        ) : (
          "Подтвердить"
        )}
      </button>
    </form>
  );
};
