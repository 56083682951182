import { useGetStaticQuery } from "@/entities/static";
import { Container } from "@/shared/ui";
import { Loading } from "@/shared/ui/loading";
import { Link } from "react-router-dom";
import { DownloadAppModal } from "@/features/download-app";
import logo from "@/shared/assets/icons/elistory-logo.svg";

export const Footer: React.FC = () => {
  const { data: contacts, isLoading } = useGetStaticQuery("");

  return (
    <footer>
      <div className="bg-blue-950 rounded-t-xl text-white pt-10 md:pt-12 md:pb-5 neutralize-mobile-menu">
        <Container>
          <div className="flex flex-col md:flex-row flex-wrap">
            <div className="flex-1 mt-2 ">
              <Link to={"/home#plates"}>
                <img src={logo} alt="" className="" />
              </Link>
              <div className="mt-4">
                <DownloadAppModal />
              </div>
            </div>
            <div className="flex-1 mt-4">
              <h3 className="text-2xl font-bold text-gray-400">Чем заняться</h3>
              <ul>
                <li>
                  <Link to={"/tours"}>Туры</Link>
                </li>
                <li>
                  <Link to={"/events"}>Афиша</Link>
                </li>
                <li>
                  <Link to={"/excursions"}>Экскурсии</Link>
                </li>
                <li>
                  <Link to={"places/list/sport-i-razvlechenie"}>
                    Спорт и развлечения
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex-1 mt-4">
              <h3 className="text-2xl font-bold text-gray-400">Куда сходить</h3>
              <ul>
                <li>
                  <Link to={"places/list/dostoprimechatelnosti"}>
                    Достопримечательности
                  </Link>
                </li>
                <li>
                  <Link to={"places/list/gastronomiya"}>Рестораны и кафе</Link>
                </li>
                <li>
                  <Link to={"places/list/place-category-1"}>Музеи</Link>
                </li>
                <li>
                  <Link to={"places/list/place-category-1"}>Театры</Link>
                </li>
                <li>
                  <Link to={"places/list/suvenirnye"}>Магазины и аптеки</Link>
                </li>
              </ul>
            </div>
            <div className="flex-1 mt-4">
              <h3 className="text-2xl font-bold text-gray-400">
                Путешественникам
              </h3>
              <ul>
                <li>
                  <a href="https://airtickets.elistory.ru/">Авиабилеты</a>
                </li>
                <li>
                  <a href="https://hotels.elistory.ru/">Отели</a>
                </li>
                <li>
                  <Link to={"/train-tickets"}>Ж/Д билеты</Link>
                </li>
                <li>
                  <Link to={"places/list/tualety"}>Туалеты</Link>
                </li>
              </ul>
            </div>
            <div className="flex-1 mt-4 text-gray-400">
              <p>
                ИП Муниев А.М.
                <br />
                ИНН 081407563384
                <br />
                ОГРНИП 319081600009565A
                <br />
                Почтовый адрес:
                <br />
                358000, Республика Калмыкия, <br />
                ул. Клыкова, 1а
              </p>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-end">
            <div className="flex-col w-full mt-4 text-gray-400 ">
              {isLoading ? (
                <Loading size="xs" />
              ) : (
                <div className="flex flex-col underline">
                  <a href={`tel:${contacts?.phone}`}>{contacts?.phone}</a>
                  <a href={`mailto:${contacts?.email}`}>{contacts?.email}</a>
                </div>
              )}
              <p className="mt-5 text-sm">© 2024 Все права защищены</p>
            </div>
            <div className="text-sm flex flex-wrap text-nowrap gap-4 order-first md:order-2 mt-10">
              <Link to={"/policy"} className="text-gray-400 link link-hover">
                Политика конфиденциальности
              </Link>
              <Link
                to={"/use-conditions"}
                className="text-gray-400 link link-hover"
              >
                Условия использования
              </Link>
              <Link
                to={"/home#plates"}
                className="text-gray-400 link link-hover"
              >
                Удалить аккаунт
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );

  return (
    <footer className="hidden pt-4 pb-20 text-white lg:pb-4 mt:-auto footer bg-base-300 lg:block">
      <Container direction="col">
        <div className="flex flex-col w-full gap-5 sm:flex-row">
          <div className="w-[50%]">
            <Link
              to={"/home#plates"}
              className="text-xl text-white uppercase navbar-start kalmyk-font"
            >
              ЭЛИСТА
            </Link>
          </div>
          <div className="flex flex-wrap gap-5 md:gap-10 lg:gap-20 lg:shrink-0">
            <nav className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Проекты</h6>
              <Link to={"/guides"} className="text-gray-400 link link-hover">
                Услуги гидов
              </Link>
            </nav>
            <nav className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Пользователи</h6>
              <Link to={"/policy"} className="text-gray-400 link link-hover">
                Политика конфиденциальности
              </Link>
              <Link
                to={"/use-conditions"}
                className="text-gray-400 link link-hover"
              >
                Условия использования
              </Link>
              <Link
                to={"/home#plates"}
                className="text-gray-400 link link-hover"
              >
                Удалить аккаунт
              </Link>
            </nav>
            <div className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Организация</h6>
              <p className="text-gray-400 ">ИП Муниев А.М.</p>
              <p className="text-gray-400 ">ИНН: 081407563384</p>
              <p className="text-gray-400 ">ОГРНИП: 319081600009565A</p>
              <p className="text-gray-400 ">
                Почтовый адрес: 358000, республика Калмыкия, ул. Клыкова, 1а
              </p>
            </div>
          </div>
          <div className="w-[50%] hidden lg:block text-end">
            <DownloadAppModal />
          </div>
        </div>
        <div className="flex flex-wrap justify-between w-full mt-4">
          {isLoading ? (
            <Loading size="xs" />
          ) : (
            <div className="flex flex-col gap-1">
              <a href={`tel:${contacts?.phone}`} className="fb hover:underline">
                {contacts?.phone}
              </a>
              <a
                href={`mailto:${contacts?.email}`}
                className="fb hover:underline"
              >
                {contacts?.email}
              </a>
            </div>
          )}

          <p className="fb">© 2024 Все права защищены</p>
        </div>
      </Container>
    </footer>
  );
};
